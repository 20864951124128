import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { theme } from '../../../domain/theme';
import { ParagraphWrapper } from '../ParagraphWrapper';
import { t } from '../../../domain/services/configService';
import { imageResizerService } from '../../../domain/services/imageResizerService';
import { ImageSimple } from '../../Image/ImageSimple';

const styles = {
  main: css`
    width: 100%;
    padding: ${theme.spacing('l')};
    margin-bottom: ${theme.spacing('l')};
    border: 1px solid #e6e6e6;
  `,
  image: css`
    text-align: center;
    margin-bottom: ${theme.spacing('base')};
    img {
      border-radius: 50%;
    }
    ${theme.mq.tablet} {
      float: right;
    }
  `,
  item: css`
    list-style: none !important;
    ${theme.font.family('regularWeb')};
    font-feature-settings: 'swsh';
    margin-bottom: ${theme.spacing('xs')};
    :last-child {
      margin-bottom: 0;
    }
    span {
      text-transform: uppercase;
      font-size: ${theme.font.size('xs')};
      letter-spacing: ${theme.letterSpacing('base')};
      ${theme.font.family('boldWeb')};
    }
    picture {
      display: block;
      margin-top: ${theme.spacing('xs')};
    }
  `
};

const AnimalProfileInner = ({ data, isAboveTheFold }) => {
  const animal = data?.animal?.entity;
  const fields = [];
  if (animal?.isPublished) {
    fields.push({ name: t('Common name'), val: animal.title });
    if (animal.scientificName) {
      fields.push({
        name: t('Scientific name'),
        val: <em>{animal.scientificName}</em>
      });
    }
    const type = animal.animalsTypeOther || t(animal.animalsType);
    if (type) {
      fields.push({ name: t('Type'), val: type });
    }
    const diet = animal.animalsDietOther || t(animal.animalsDiet);
    if (diet) {
      fields.push({ name: t('Diet'), val: diet });
    }
    if (animal.groupName) {
      fields.push({ name: t('Group name'), val: animal.groupName });
    }
    if (animal.lifeSpanInCaptivity) {
      fields.push({
        name: t('Average life span in captivity'),
        val: animal.lifeSpanInCaptivity
      });
    }
    if (animal.lifeSpanInTheWild) {
      fields.push({
        name: t('Average life span in the wild'),
        val: animal.lifeSpanInTheWild
      });
    }
    if (animal.lifeSpan) {
      fields.push({ name: t('Average life span'), val: animal.lifeSpan });
    }
    if (animal.size) {
      fields.push({ name: t('Size'), val: animal.size });
    }
    if (animal.weight) {
      fields.push({ name: t('Weight'), val: animal.weight });
    }
    if (
      animal.sizeRelativeTo &&
      animal.sizeRelativeToThumbnail?.entity?.mediaImage?.url
    ) {
      fields.push({
        name:
          animal.sizeRelativeTo === 'other'
            ? animal.sizeRelativeToOther
            : t(`Size relative to ${animal.sizeRelativeTo}`),
        val: (
          <ImageSimple
            src={imageResizerService.getStyleUrl(
              animal.sizeRelativeToThumbnail.entity.mediaImage.url,
              160,
              100
            )}
            width={160}
            height={100}
            alt={t(`Size relative to ${animal.sizeRelativeTo}`)}
            loading={isAboveTheFold ? 'lazy' : 'eager'}
          />
        )
      });
    }
  }
  return fields.length > 3 ? (
    <section css={styles.main}>
      {animal.image && (
        <div css={styles.image}>
          <ImageSimple
            src={imageResizerService.getStyleUrl(
              animal.image.entity.mediaImage.url,
              134,
              134
            )}
            width={134}
            height={134}
            alt={animal.title}
            loading={isAboveTheFold ? 'lazy' : 'eager'}
          />
        </div>
      )}
      <ul>
        {fields.map(field => (
          <li css={styles.item} key={field.name}>
            <span>{field.name}:</span> {field.val}
          </li>
        ))}
      </ul>
    </section>
  ) : null;
};

AnimalProfileInner.defaultProps = {
  isAboveTheFold: false
};

AnimalProfileInner.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  isAboveTheFold: PropTypes.bool
};

export const AnimalProfile = ParagraphWrapper(AnimalProfileInner);
